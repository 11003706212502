.itemDetail{
    display: block;
}

.itemDetail .BackToWrap{
    height:40px;
    line-height:40px;
    text-align:left;
    padding-left:20px;
}

.itemDetail .BackToWrap .BackToButton{
    cursor:pointer;
}

.itemDetail .itemInfo.desktop{
    display:flex;
}

.itemDetail .itemInfo.desktop .imageTileWrap{
    flex-grow:1;
}
.itemDetail .itemInfo.desktop .ItemDetailInfo{
    flex-basis: 380px;
    flex-shrink: 0;
    flex-grow:0;
    padding-right:10px;
}

.image_tile_only_one{
    padding:10px;
    margin:10px;
    background-color: #eee;
    text-align: center;
}
.image_tile_only_one img{
    max-width: 100%;
    margin:0 auto;
}

.image_tile{
    text-align:center;
}

.image_tile .image_tile_row{
    width:100%;
    display:flex;
}
.image_tile_left{
    width:50%;
    flex-grow: 0;
    padding:10px;
    padding-right:5px;

}
.image_tile_right{
    width:50%;
    flex-grow: 0;
    padding:10px;
    padding-left:5px;
}

.image_tile_left img{
    max-width:100%;
}

.image_tile_right img{
    max-width:100%;
}

.itemDetail .itemInfo.mobile{
    display:block;
}

.itemDetail .itemInfo.mobile .imageSwipwerWrap{
    width:100%;
    height:300px;
    background-color: #eee;
}

.ItemDetailInfo{
    text-align:center;
}

.ItemDetailInfo .add_to_cart_button{
    cursor: pointer;
    background-color: black;
    color:white;
    font-size:16px;
    line-height:40px;
    max-width:400px;
    margin:0 auto;
}

.ItemDetailInfo .details .title{
    line-height:50px;
    font-size:13px;
}

.ItemDetailInfo .details table{
    margin:0 auto;
    font-size:13px;
    line-height:20px;
}

.ItemDetailInfo .details table tr td:first-child{
    text-align:right;
    padding-right:10px;
}
.ItemDetailInfo .details table tr td:last-child{
    text-align:left;
}