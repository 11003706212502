.topMenu{
    display: flex;  
    height: 60px;  
}

.topMenulogo{
    display:inline-flex;
    align-items: center;
    font-size:12pt;
    font-style:italic;
    font-weight:500;
    color:#444;
    text-decoration: none !important;
}

.topMenulogo a{
    text-decoration: none !important;
    color:#444;
}

.topMenuButton{
    display:inline-flex;
    flex-grow:0;
    align-items: center;
    width:50px;
    height:50px;
    margin:5px;
    font-size:5pt;
    cursor:pointer;
    transition: all 2s linear;/*일단 state를 쓰기 때문에 동작 안함 */
}
.topMenuButton img{
    margin:0px auto;
    align-items:center;
    width:24px;
    height:24px;
}

.topMenuButton.isMenuVisible{
    transform: rotate(-90deg);
}

.topTextMenu{
    display: inline-flex;
    flex-grow:1;
    justify-content: flex-end;
}

.topTextMenu a{
    text-decoration: none !important;
    color:#444;
}

.topIconMenu{
    display: none;
    flex-grow:1;
    justify-content: flex-end;
}
.topTextMenu .menuItem{
    display:inline-flex;
    font-size:16px;
    font-weight:500;
    line-height:50px;
    margin:5px;
    margin-right:20px;
    cursor:pointer;
}

.topIconMenu .menuItem{
    display:inline-flex;
    align-items: center;
    width:40px;
    height:50px;
    margin:5px;
    font-size:5pt;
    cursor:pointer;
}

.topIconMenu .menuItem img{
    margin:0px auto;
    align-items:center;
    width: 20px;
    height:20px;
}


@media screen and (max-width: 980px){
    .topTextMenu {
        display: none;
    }
    .topIconMenu{
        display: inline-flex;
    }

}