.modalCartPage{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:none;
    margin:0px;
    padding:0px;
    background-color: rgba(0,0,0,0.4);
    animation: fadein 0.25s;
}
@keyframes fadein {
    from {  opacity:0; }
    to {    opacity:1; }
}

.modalCartPage .cartPageWrap{
    width:300px;
    height:100%;
    position:absolute;
    right:0px;
    background-color: white;
    overflow:hidden;
}

.modalCartPage .cartPageWrap .toplist{
    display: block;
    flex-direction: column-reverse;
}

.modalCartPage .cartPageWrap .toplist .title{
    display:block;
    font-size: 16px;
    line-height:60px;
    height:60px;
    padding-left:20px;
}

.modalCartPage .cartPageWrap .closeButton{
    display: block;
    position:absolute;
    top:0px;
    right:0px;
    padding:20px;
    cursor: pointer;
}

.modalCartPage .cartPageWrap .closeButton:hover{
    filter:alpha(opacity=50);
    opacity: 0.5;
}