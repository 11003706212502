body {
  margin: 0;
  font-family: NanumGothic,Arial,AppleSDGothicNeo-Medium,Malgun,sans-serif,"HelveticaNeueLTPro-BdCn","Helvetica Neue",Helvetica,"Arial Narrow",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, div, textarea, button{
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-sizing:border-box;
  -o-sizing:border-box;
  box-sizing:border-box;
}

table th td{
  border-collapse: collapse;
}