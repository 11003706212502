.modalLeftMenu{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:none;
    margin:0px;
    padding:0px;
    background-color: rgba(0,0,0,0.4);
    animation: fadein 0.2s;
}
@keyframes fadein {
    from {  opacity:0; }
    to {    opacity:1; }
}

.modalLeftMenu .menuWrap{
    width:300px;
    height:100%;
    background-color: white;
    overflow:hidden;
}

.modalLeftMenu .menuWrap .toplist{
    display: flex;
}

.modalLeftMenu .menuWrap .toplist span{
    font-size: 16px;
    padding:16px;
    color:#aaa;
    cursor:pointer;
}

.modalLeftMenu .menuWrap .toplist .closeButton:hover{
    filter:alpha(opacity=50);
    opacity: 0.5;
}

.modalLeftMenu .menuWrap .toplist span.active, .modalLeftMenu .menuWrap .toplist span:hover{
    color:black;
}

.modalLeftMenu .menuWrap .menuList{
    display: none;
    text-align: left;
}
.modalLeftMenu .menuWrap .menuList.active{
    display: block;
}

.modalLeftMenu .menuWrap .menuList .item{
    display:block;
    font-size:20px;
    font-weight:700;
    margin-left:16px;
    cursor:pointer;
}
.modalLeftMenu .menuWrap .menuList .item:hover{
    color:#888;
}

.modalLeftMenu .menuWrap .bottomlist{
    cursor:pointer;
    display:block;
    background-color: white;
    height:60px;
    line-height:60px;
    position:absolute;
    bottom:0px;
    width:300px; /*100% ?? */
    text-align:right;
    padding-right:20px;
}

.modalLeftMenu .menuWrap .bottomlist a{
    text-decoration: none;
    color:#444;
}