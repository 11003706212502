.loginWrap{
    margin: 0 auto;
    min-width:300px;
    max-width:500px;
    text-align:center;
    padding:24px;
    padding-top:32px;
}

.loginWrap .title{
    font-size:19px;
    font-weight:400;
    font-style:italic;
    font-weight: bold;
}

.loginWrap .subtitle{
    font-size:18px;
    font-weight:400;
}

.loginWrap .msg{
    font-size:14px;
    font-weight:400;
}

.loginWrap form input{
    display:block;
    width: 100%;
    line-height:14px;
    padding:15px;
    margin-bottom:15px;
    border:1px solid rgb(219,219,219);
    transition: 0.5s all;

}
.loginWrap form input:focus{
    border:1px solid black;
    border-radius: 0;
}

.loginWrap form span{
    display: block;
    margin-bottom: 14px;
    text-align:right;
    width:100%;
    font-size:14px;
}
.loginWrap form span:hover{
    text-decoration: underline;
    cursor: pointer;
}

.loginWrap form button{
    display: block;
    width: 100%;
    line-height:14px;
    padding:15px;
    background-color: black;
    border:0px;
    color:white;
    text-align:center;
    font-size:14px;
}
.loginWrap form button:hover{
    background-color: rgb(132,134,134);
}

.loginWrap .submsg{
    font-size:14px;
    margin-top:15px;
}