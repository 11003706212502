.itemThumbnailShowWrap{
    width:100%;
    height:100%;
    overflow:hidden;
}

.itemThumbnail{
    width:100%;
    height:100%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
}

.itemThumbnail img{
    max-width: 100%;
    max-height:100%;
}