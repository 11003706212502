.hoverToTopBtn{
    position:fixed;
    right:15px;
    bottom:15px;
    z-index: 99999;
    background: white;
    border-radius: 20px;
    border:1px solid #eee;
    cursor:pointer;
    text-align: center;
    vertical-align: middle;
    padding-top:8px;
    width:50px;
    height:50px;
}