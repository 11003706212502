.MyPage .topMenu{
    display: flex;  
    height: 60px;  
}

.MyPage .title{
    display:inline-flex;
    align-items: center;
    font-size:12pt;
    font-weight:500;
    color:#444;
}

.MyPage .BackButton{
    display:inline-flex;
    flex-grow:0;
    align-items: center;
    width:50px;
    height:50px;
    margin:5px;
    font-size:5pt;
    cursor:pointer;
    transition: all 2s linear;/*일단 state를 쓰기 때문에 동작 안함 */
}
.MyPage .BackButton img{
    margin:0px auto;
    align-items:center;
    width:24px;
    height:24px;
}

.MyPage .welcomeBar{
    border-top:1px solid #eee;
    display: flex;
    width:100%;
    height:40px;
}
.MyPage .welcomeBar div{
    display: inline-flex;
    font-size:16px;
    font-weight:500;
    line-height:40px;
    margin:5px;
    margin-right:20px;
}
.MyPage .welcomeBar .welcome{
    flex-grow:1;
    padding-left:20px;
}
.MyPage .welcomeBar .logoutButton{
    flex-grow: 0;
    cursor:pointer;
}