.ItemMobile{
    display: flex;
    width:100%;
    margin-bottom:20px;
}

.ItemMobile .imageWrap{
    display: inline-flex;
    width:100px;
    flex-grow: 0;
    height:100px;
    background-color: #eee;
}

.ItemMobile .textWrap{
    margin-left:10px;
    padding-top:5px;
    padding-bottom:5px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.ItemMobile .textWrap .brand{
    display: flex;
    font-size:14px;
    flex-grow: 0;
}

.ItemMobile .textWrap .item_code{
    display: flex;
    margin-top:3px;
    font-size:14px;
    flex-grow: 0;
    color:#888;
}

.ItemMobile .textWrap .spacer{
    display:flex;
    flex-grow: 1;
}
.ItemMobile .textWrap .description{
    display:flex;
    flex-grow:0;
    font-size:14px;
    margin-bottom:5px;
    
}

.ItemMobile .textWrap .order_deadline{
    display: flex;
    flex-grow: 0;
    font-size:12px;
}


.ItemMobile .priceWrap{
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    text-align: right;
    padding-top:5px;
    padding-bottom:5px;
}

.ItemMobile .priceWrap .priceOriginal{
    font-size:15px;
    color:rgb(172, 83, 83);
    font-weight: 600;
    text-decoration:line-through;
    flex-grow: 0;
}

.ItemMobile .priceWrap .priceSales{
    font-size:15px;
    color:black;
    font-weight: 700;
    flex-grow: 0;
}
.ItemMobile .priceWrap .spacer{
    flex-grow: 1;
}
.ItemMobile .priceWrap .stock{
    font-size:16px;
    color:black;
    flex-grow: 0;
    font-size:14px;
}

.ItemListWrap{
    padding-left: 20px;
    padding-right:20px;
}

.ItemListWrap.tablet{
    display: flex;
}
.ItemListWrap.tablet .FilterAreaWrap{
    width:300px;
    display:inline-block;
    flex-grow: 0;
}

.ItemListWrap.tablet .ItemListTableAreaWrap{
    display:inline-block;
    flex-grow: 1;
}

.ItemListWrap .FilterButtonWrap{
    display:block;
    width:100%;
    text-align:right;
}
.ItemListWrap .FilterButtonWrap .FilterButton{
    cursor:pointer;
}

.ItemListTable{
    border-collapse: collapse;
    display:inline-table;
    width:100%;
    font-size:14px;
    font-weight:200;
}
.ItemListTable thead tr th{
    border-bottom:1px solid #aaa;
    font-weight:200;
    font-size:12px;
    text-align:left;
}

.ItemDesktop td{
    margin-top:5px;
    vertical-align: top;
}

.ItemDesktop td.image{
    width:120px;
}
.ItemDesktop .imageWrap{
    width:100px;
    height:100px;
    background-color: #eee;
}

.ItemDesktop td{
    padding-top:10px;
}

.ItemDesktop .priceOriginal, .priceSalesWrap{
    text-align:right;
}
.ItemDesktop .priceOriginal{
    text-decoration: line-through;
}

.ItemDesktop .priceSalesWrap .priceSales{
    font-weight:600;
}
.ItemDesktop .priceSalesWrap .stock{
    margin-top:20px;
}